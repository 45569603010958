import type { IO365ServiceWorkerGlobalScope } from 'o365.pwa.declaration.sw.O365ServiceWorkerGlobalScope.d.ts';

declare var self: IO365ServiceWorkerGlobalScope;

(() => {
    function getAppIDFromURL(url: URL): string {
        const pathName = url.pathname;
        const pathNameSplit: Array<string> = pathName.split("/");
        const appID = pathNameSplit[pathNameSplit.length - 1];

        return appID;
    }

    self.o365.exportScripts({ getAppIDFromURL });
})();
